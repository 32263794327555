<template>
  <div id="app">
    <logo></logo>
    <div class="animation-container">
      <lottie
        v-if="selectedAnimation"
        ref="animation"
        class="animation"
        :options="defaultOptions"
        @animCreated="handleAnimation"
      />
    </div>
    <div id="footer">
      Magiando Srl Corso Venezia 2, 20121 Milano Capitale Sociale: 10.000 € -
      REA: MI–2607007 - CF e P.IVA. 11494450965 PEC: magiando@pec.it
    </div>
  </div>
</template>

<script>
import Lottie from "vue-lottie";
import * as lighthouse from "./assets/lighthouse.json";
import * as launch from "./assets/launch.json";

import Logo from "@/components/logo/Logo";
import * as LottieInteractivity from "@lottiefiles/lottie-interactivity";
export default {
  name: "App",
  components: {
    Logo,
    Lottie,
  },
  data() {
    return {
      animationSpeed: 1,
      anim: null,
      animationList: [
        { animation: lighthouse, length: 100 },
        { animation: launch, length: 120 },
      ],
      selectedAnimation: null,
    };
  },
  computed: {
    defaultOptions() {
      return {
        animationData: this.selectedAnimation.animation.default,
      };
    },
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;

      LottieInteractivity.create({
        mode: "cursor",
        player: this.anim,
        actions: [
          {
            position: { x: [0, 1], y: [0, 1] },
            type: "seek",
            frames: [0, this.selectedAnimation.length],
          },
        ],
      });
    },
  },
  mounted() {
    this.selectedAnimation = this.animationList[
      Math.floor(Math.random() * this.animationList.length)
    ];
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.animation-container {
  width: 25vw;
  margin: 40px auto;
}
@media only screen and (max-width: 1600px) {
  .animation-container {
    width: 38vw;
  }
}
@media only screen and (max-width: 900px) {
  .animation-container {
    width: 50vw;
  }
}
#footer {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 15px 10px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: -1px 0px 2px 1px #54236d;
}
</style>
